<template>
    <Dropdown
        v-if="isUseTitleType"
        name="titleType"
        :required="required"
        :title="filterTitles.titleType"
        :options="filterOptions.titleType"
        v-model="titleTypeValue"
    />
    <Dropdown
        name="manCode"
        placeholder="請輸入帳號姓名"
        tips="介紹報備帳號請參考介紹人資訊"
        :required="required"
        :searchable="true"
        :title="filterTitles.manCode"
        :options="filterOptions.manCode"
        :hasSelectedALL="hasSelectedALL"
        v-model="manCodeValue"
    />
</template>
<script>
import _ from 'lodash'
import Dropdown from '@/components/Dropdown.vue'

export default {
    name: 'TitleTypeAccountFilter',
    emits: ['update:titleType', 'update:manCode', 'update:manCodes'],
    components: {
        Dropdown
    },
    props: {
        isUseTitleType: {
            type: Boolean,
            default: true
        },
        required: {
            type: Boolean,
            default: true
        },
        titleType: {
            type: [String, Number],
            default: ''
        },
        titleTypeOptions: {
            type: Array,
            default: function () {
                return []
            }
        },
        manCode: {
            type: [String, Number],
            default: ''
        },
        manCodes: {
            type: Array,
            default: function () {
                return []
            }
        },
        hasSelectedALL: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        resetFilter: async function () {
            // 在帳號清單(下拉選單)中找出登入者資訊
            const accountInfo = this.$store.state.accounts.find(
                (item) => item.key == this.$store.state.userInfo.code
            )

            // 制度別: 下拉選單找不到登入帳號的所屬制度別，就預設選第一個制度別
            if (!this.hasSelectedALL) {
                this.titleTypeValue =
                    accountInfo?.titleTypeId ||
                    this.filterOptions.titleType[0].value
            } else {
                this.titleTypeValue = this.filterOptions.titleType[0].value
            }

            // 帳號(manCode): 下拉選單找不到登入的帳號，預設帶空字串。
            // admin:不帶預設帳號，讓admin可以看下拉選單的全部選項
            if (this.$isAdmin()) {
                this.manCodeValue = ''
            } else this.manCodeValue = accountInfo?.key || ''
        },
        checkManCode: function () {
            const selectedManCode = {
                value: this.manCodeValue
            }
            let newListIncludesManCode
            if (this.manCodes.length > 0) {
                newListIncludesManCode = this.filterOptions.manCode
                    .map((item) => item.value)
                    .includes(selectedManCode.value[0])
            } else {
                newListIncludesManCode = this.filterOptions.manCode
                    .map((item) => item.value)
                    .includes(selectedManCode.value)
            }

            if (!newListIncludesManCode) {
                this.manCodeValue = ''
            }
        },
        setTitleTypeFromManCode: function () {
            let accountInfo
            accountInfo = this.$store.state.accounts.find(
                (data) => data.key == this.manCodes
            )
            if (accountInfo) {
                this.titleTypeValue = accountInfo.titleTypeId
            }
        },
        getManCodeLabel: function (data) {
            if (data) {
                if (data?.apStuName)
                    return `${data.value}(介紹人:${data?.apStuName})`
                else if (data?.isMainAccount)
                    return data?.leaderBgCodes.length > 0
                        ? `${data.value}(執行長)(現行帳號)`
                        : `${data.value}(現行帳號)`
                else if (data?.leaderBgCodes.length > 0)
                    return `${data.value}(執行長)`
                else return data.value
            }
            return ''
        }
    },
    computed: {
        titleTypeValue: {
            get() {
                return this.titleType
            },
            set(val) {
                this.$emit('update:titleType', val)
            }
        },
        manCodeValue: {
            get() {
                if (this.hasSelectedALL) {
                    return this.manCodes
                } else {
                    return this.manCode
                }
            },
            set(val) {
                //因應客戶服務頁面修改 payload架構
                if (this.hasSelectedALL) {
                    let manCodeList = []
                    //當選擇全選時
                    if (this.titleTypeValue === 'all') {
                        let currentUserManCode = +this.$store.state.userInfo
                            .code
                        manCodeList = this.$store.state.accounts.map((data) => {
                            return +data.key
                        })
                        let index = manCodeList.indexOf(currentUserManCode)
                        manCodeList.splice(index, 1)
                        manCodeList.unshift(currentUserManCode)
                    } else {
                        manCodeList[0] = +val
                    }
                    this.$emit('update:manCodes', manCodeList)
                } else {
                    this.$emit('update:manCode', val)
                }
            }
        },
        filterOptions: function () {
            let dropdownOptions = _.cloneDeep(this.$store.state.titleTypes)
            if (
                this.hasSelectedALL &&
                dropdownOptions.find((option) => option.key === 'all') ===
                    undefined &&
                !this.$isAdmin()
            )
                dropdownOptions.unshift({ key: 'all', value: '全部' })
            return {
                titleType: dropdownOptions
                    .filter((data) => {
                        if (this.titleTypeOptions.length === 0) return true
                        return this.titleTypeOptions.includes(data.key)
                    })
                    .map((data) => {
                        return {
                            label: `${data.value ? data.value : '其他'}`,
                            value: `${data.key ? data.key : ''}`
                        }
                    }),
                manCode: this.manCodeOptions,
                manCodes: this.manCodeOptions
            }
        },
        manCodeOptions: function () {
            if (this.titleTypeValue === 'all') {
                return [
                    {
                        label: '全部',
                        titleTypeId: 'all',
                        value: '',
                        isSelectedAll: true
                    }
                ]
            } else {
                return _.orderBy(
                    this.$store.state.accounts
                        .map((data) => {
                            return {
                                label: this.getManCodeLabel(data),
                                value: data.key,
                                titleTypeId: data.titleTypeId
                            }
                        })
                        .filter((data) => {
                            if (!this.isUseTitleType) return true
                            if (this.titleTypeValue)
                                return data.titleTypeId == this.titleTypeValue
                            else return true
                        }),
                    'label',
                    'asc'
                )
            }
        }
    },
    watch: {
        'filterOptions.manCode': function () {
            this.checkManCode()
        },
        'filterOptions.titleType': {
            handler(val) {
                const selectedOption = val.find(
                    (option) => option.value === this.titleTypeValue
                )
                if (!selectedOption) {
                    this.titleTypeValue = val[0]?.value
                }
            },
            immediate: true
        },
        manCode: {
            handler() {
                // 若有預設指定的manCode，則回推對應的制度別
                if (this.manCode) {
                    this.setTitleTypeFromManCode()
                } else {
                    // 一開始預設第1個制度別 (不在mounted呼叫resetFilter是因為若父層有給預設值會被覆蓋成空值)
                    if (!this.titleTypeValue) this.resetFilter()
                }
            },
            immediate: true
        },
        manCodes: {
            handler() {
                if (!this.titleTypeValue) this.resetFilter()
            }
        }
    },
    data() {
        return {
            manCodeLabel: '',
            filterTitles: {
                titleType: '制度',
                manCode: '帳號',
                _seq: ['titleType', 'manCode']
            }
        }
    }
}
</script>
